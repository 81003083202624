
import Vue from 'vue';
import utils from '../../util';
import HeaderLogoC360 from '../components/toolbar/headerLogoC360.vue';
import { C360Provider, defineAbilityFor, useLink } from '@c360/ui';

export default Vue.extend({
  components: {
    HeaderLogoC360,
    AppGlobals: () => import('../global/globals.vue'),
    C360Footer: () => import('../global/footerC360.vue'),
    C360Provider,
  },
  data(): {} {
    return { refreshToken: '' };
  },
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    redirectLink(): string {
      const agency = utils.getAgencyFromURL();
      return useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
    },
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;

    this.$store
      .dispatch('logout', { token: this.$route?.query?.token, error: !!this.$route?.params?.notFoundInDSError })
      .then(() => {
        localStorage.removeItem('hasUpdatedHomeCache');
        localStorage.removeItem('ah:products');
        localStorage.removeItem('ah:jwtToken');
        localStorage.removeItem('ah:refreshToken');
        localStorage.removeItem('ah:accessToken');
        window.location.href = `${this.redirectLink}/login`;
        return;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error('logout error', error);
      });
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
});
